<template>
	<div class="siteset-wechat el-content">
        <a-tabs>
            <a-tab-pane key="1" tab="微信第三方平台配置">
                <com-power-form :form-data="[
                    {label:'微信第三方授权',type:'radio',key:'wx_open_switch',dataType:'string',
                        options:[
                            {label:'开启',value:'1'},
                            {label:'关闭',value:'0'},
                        ]
                    },
                    {label:'微信开放平台APPID',type:'text',key:'wx_open_appid',dataType:'string'},
                    {label:'微信开放平台secret',type:'text',key:'wx_open_appsecert',dataType:'string'},
                    {label:'微信开放平台TOKEN',type:'text',key:'wx_open_token',dataType:'string'},
                    {label:'微信开放平台aes_key',type:'text',key:'wx_open_aes_key',dataType:'string'},
                    {label:'小程序模板ID',type:'text',key:'wx_open_min_program_template_id',dataType:'string'},
                    {label:'小程序预览页面',type:'text',key:'wx_open_min_program_index_page',dataType:'string'},
                ]"></com-power-form>
            </a-tab-pane>
            <a-tab-pane key="2" tab="网站扫码登陆" >
                <com-power-form :form-data="[
                    {label:'微信公众号APPID',type:'text',key:'wx_open_web_appid',dataType:'string'},
                    {label:'微信公众号secret',type:'text',key:'wx_open_web_secret',dataType:'string'},
                ]"></com-power-form>
            </a-tab-pane>
        </a-tabs>
			
	</div>
</template>

<script>
import comPowerForm from '@/components/form/com-power-form.vue'
export default {
	components:{
		comPowerForm
	},
}
</script>

<style></style>
